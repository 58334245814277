let mockShelf = [
    {
      id: "EL3YmDLY6W",
      name: "Risk",
      year_published: 1959,
      min_players: 2,
      max_players: 6,
      min_playtime: 120,
      max_playtime: 120,
      min_age: 10,
      description:
        "<p>Welcome to the world of <strong>Risk</strong>. It’s exciting. It’s unpredictable. It’s all about battling your way to global domination, one territory at a time. And it’s easy to get into the game, fast!</p>\r\n<p>In Risk, you focus on your strategy. You decide on your tactical scheme. You figure out where to place your troops, and who and where you will attack and how often. Every move is a risk that will bring either victory or defeat. Go for the win!</p>\r\n<p><strong>60th Anniversary Edition:</strong></p>\r\n<p>Betrayal. Alliances. Surprise attacks. Risk continues to be one of the world's most popular and influential strategic board games decades after its inception. The special 60th Anniversary Edition of Risk celebrates its legacy with premium packaging and game pieces. For true Risk fans, the included game guide reveals the history of Risk. It also features 5 ways to play, including the classic game plus the original 1957 <em>La conquete du monde</em> rules.</p>\r\n<p>Anything goes on the battlefield of the risk game. Using strategy, smarts, and a little bit of luck, a player will either defeat the enemy or be defeated. Take over the world in this game of strategy conquest!</p>",
      description_preview:
        " Welcome to the world of  Risk . It’s exciting. It’s unpredictable. It’s all about battling your way to global domination, one territory at a time. And it’s easy to get into the game, fast! \r\n In Risk, you focus on your strategy. You decide on your tactical scheme. You figure out where to place your troops, and who and where you will attack and how often. Every move is a risk that will bring either victory or defeat. Go for the win! \r\n  60th Anniversary Edition:  \r\n Betrayal. Alliances. Surprise attacks. Risk continues to be one of the world's most popular and influential strategic board games decades after its inception. The special 60th Anniversary Edition of Risk celebrates its legacy with premium packaging and game pieces. For true Risk fans, the included game guide reveals the history of Risk. It also features 5 ways to play, including the classic game plus the original 1957  La conquete du monde  rules. \r\n Anything goes on the battlefield of the risk game. Using strategy, smarts, and a little bit of luck, a player will either defeat the enemy or be defeated. Take over the world in this game of strategy conquest! ",
      image_url:
        "https://s3-us-west-1.amazonaws.com/5cc.images/games/uploaded/1559254847937-51zyVWbt8aL.jpg",
      thumb_url:
        "https://s3-us-west-1.amazonaws.com/5cc.images/games/uploaded/1559254847937-51zyVWbt8aL.jpg",
      images: {
        thumb:
          "https://d2k4q26owzy373.cloudfront.net/40x40/games/uploaded/1559254847937-51zyVWbt8aL.jpg",
        small:
          "https://d2k4q26owzy373.cloudfront.net/150x150/games/uploaded/1559254847937-51zyVWbt8aL.jpg",
        medium:
          "https://d2k4q26owzy373.cloudfront.net/350x350/games/uploaded/1559254847937-51zyVWbt8aL.jpg",
        large:
          "https://d2k4q26owzy373.cloudfront.net/700x700/games/uploaded/1559254847937-51zyVWbt8aL.jpg",
        original:
          "https://s3-us-west-1.amazonaws.com/5cc.images/games/uploaded/1559254847937-51zyVWbt8aL.jpg",
      },
      url: "https://www.boardgameatlas.com/game/EL3YmDLY6W/risk",
      price: "29.99",
      msrp: "0.00",
      discount: "0.00",
      primary_publisher: "Hasbro Games",
      publishers: ["Hasbro"],
      mechanics: [
        {
          id: "05zCZoLvQJ",
          url: "https://www.boardgameatlas.com/mechanic/05zCZoLvQJ/undefined",
        },
        {
          id: "bgGxE0pI2B",
          url: "https://www.boardgameatlas.com/mechanic/bgGxE0pI2B/undefined",
        },
        {
          id: "R0bGq4cAl4",
          url: "https://www.boardgameatlas.com/mechanic/R0bGq4cAl4/undefined",
        },
        {
          id: "BGrhzIN69D",
          url: "https://www.boardgameatlas.com/mechanic/BGrhzIN69D/undefined",
        },
        {
          id: "lA3KUtVFCy",
          url: "https://www.boardgameatlas.com/mechanic/lA3KUtVFCy/undefined",
        },
      ],
      categories: [
        {
          id: "buDTYyPw4D",
          url: "https://www.boardgameatlas.com/category/buDTYyPw4D/undefined",
        },
        {
          id: "jX8asGGR6o",
          url: "https://www.boardgameatlas.com/category/jX8asGGR6o/undefined",
        },
      ],
      designers: ["Albert Lamorisse", "Michael I. Levin"],
      developers: [],
      artists: ["(Uncredited)"],
      names: [
        "Risk: The Game of Strategic Conquest",
        "Ataque",
        "La Conquista del mundo",
        "Risc Commander",
        "Risco",
        "Risiko",
        "Risiko Deluxe",
        "Risiko!",
        "Risk de luxe",
        "Risk wereldveroverend",
        "Risk Η κατάκτηση του κόσμου",
        "Risk Το παιχνίδι της παγκόσμιας κυριαρχίας",
        "Risk. Spillet der du erobrer verden",
        "Risk. Игра в завоевание мира",
        "Risk: Spillet om Verdensherredømmet",
        'Risk: Επιχείρηση "Μυστική Αποστολή"',
        "Rizikó",
        "Ryzyko",
        "War",
        "סיכון",
        "RISK 60th Anniversary Edition",
      ],
      num_user_ratings: 180,
      average_user_rating: 2.440632603406325,
      official_url: null,
      rules_url: null,
      matches_specs: null,
      spec: [],
      reddit_all_time_count: 177,
      reddit_week_count: 1,
      reddit_day_count: 10,
      historical_low_price: 20.99,
      historical_low_date: "2019-04-22T00:42:17.972Z",
      rank: 324,
      trending_rank: 256,
    },
    {
      id: "fG5Ax8PA7n",
      name: "Monopoly",
      year_published: 1933,
      min_players: 2,
      max_players: 8,
      min_playtime: 40,
      max_playtime: 300,
      min_age: 8,
      description:
        "<p>The thrill of bankrupting an opponent, the possibility of playing a 9 hour game, and the absolution between winning and losing, could change with the roll of the dice. Experience the ups and downs by collecting property color’s sets to build houses, and hopefully upgrading to a hotel. But don’t play just for certain colors, or else your opponent may change their strategy. </p><p>The more properties each player owns, the more rent can be charged, but trading is where the game can help you, or hurt you. So, be wise!</p><p>Chance cards could be worth money, like $10 for winning second place in a beauty pageant or sending you to “Go” for an extra $200; but you might just get the one that says, “Go To Jail”.</p><p>Buy, Sell, Dream and Scheme to Win it All!</p><p>Turn a debt into an ultimate victory!</p>",
      description_preview:
        " The thrill of bankrupting an opponent, the possibility of playing a 9 hour game, and the absolution between winning and losing, could change with the roll of the dice. Experience the ups and downs by collecting property color’s sets to build houses, and hopefully upgrading to a hotel. But don’t play just for certain colors, or else your opponent may change their strategy.   The more properties each player owns, the more rent can be charged, but trading is where the game can help you, or hurt you. So, be wise!  Chance cards could be worth money, like $10 for winning second place in a beauty pageant or sending you to “Go” for an extra $200; but you might just get the one that says, “Go To Jail”.  Buy, Sell, Dream and Scheme to Win it All!  Turn a debt into an ultimate victory! ",
      image_url:
        "https://s3-us-west-1.amazonaws.com/5cc.images/games/uploaded/1543278754636",
      thumb_url:
        "https://s3-us-west-1.amazonaws.com/5cc.images/games/uploaded/1543278754636",
      images: {
        thumb:
          "https://d2k4q26owzy373.cloudfront.net/40x40/games/uploaded/1543278754636",
        small:
          "https://d2k4q26owzy373.cloudfront.net/150x150/games/uploaded/1543278754636",
        medium:
          "https://d2k4q26owzy373.cloudfront.net/350x350/games/uploaded/1543278754636",
        large:
          "https://d2k4q26owzy373.cloudfront.net/700x700/games/uploaded/1543278754636",
        original:
          "https://s3-us-west-1.amazonaws.com/5cc.images/games/uploaded/1543278754636",
      },
      url: "https://www.boardgameatlas.com/game/fG5Ax8PA7n/monopoly",
      price: "25.99",
      msrp: "19.99",
      discount: "-0.30",
      primary_publisher: "Hasbro Games",
      publishers: ["Hasbro"],
      mechanics: [
        {
          id: "AZxlPpi5oq",
          url: "https://www.boardgameatlas.com/mechanic/AZxlPpi5oq/undefined",
        },
        {
          id: "BGrhzIN69D",
          url: "https://www.boardgameatlas.com/mechanic/BGrhzIN69D/undefined",
        },
        {
          id: "mGBzR68m8Z",
          url: "https://www.boardgameatlas.com/mechanic/mGBzR68m8Z/undefined",
        },
        {
          id: "lA3KUtVFCy",
          url: "https://www.boardgameatlas.com/mechanic/lA3KUtVFCy/undefined",
        },
        {
          id: "AVY6EvSQTP",
          url: "https://www.boardgameatlas.com/mechanic/AVY6EvSQTP/undefined",
        },
      ],
      categories: [
        {
          id: "N0TkEGfEsF",
          url: "https://www.boardgameatlas.com/category/N0TkEGfEsF/undefined",
        },
        {
          id: "jZEDOpx07e",
          url: "https://www.boardgameatlas.com/category/jZEDOpx07e/undefined",
        },
      ],
      designers: ["Charles Darrow", "Elizabeth J. Magie (Phillips)"],
      developers: [],
      artists: ["Frantz Rey"],
      names: [
        "Monopoly board game",
        "Monopoly: 80th Anniversary Edition ",
        "Monopoly Classic Game",
        "Monopoly",
        "Winning Solutions Monopoly Nostalgia Tin Board Games",
        "Monopoly Classic",
        "Classic Monopoly",
      ],
      num_user_ratings: 165,
      average_user_rating: 1.8000000000000005,
      official_url:
        "https://shop.hasbro.com/en-us/product/monopoly-classic-game:7EABAF97-5056-9047-F577-8F4663C79E75?utm_source=boardgameatlas.com&utm_medium=search&utm_campaign=bga_ads",
      rules_url: "https://www.hasbro.com/common/instruct/monins.pdf",
      matches_specs: null,
      spec: [],
      reddit_all_time_count: 222,
      reddit_week_count: 0,
      reddit_day_count: 1,
      historical_low_price: 12.47,
      historical_low_date: "2020-09-06T10:42:15.125Z",
      rank: 566,
      trending_rank: 0,
    },
    {
      id: "levMwXaCM6",
      name: "Monopoly Deal Card Game",
      year_published: 2008,
      min_players: 2,
      max_players: 5,
      min_playtime: 15,
      max_playtime: 30,
      min_age: 8,
      description:
        "<p>Get a handy way to play the classic property-trading game! Be the first collect 3 full property sets of different colors, and you’ll win the Monopoly Deal Card Game. You’ll pick up cards when it's your turn and play Action cards to charge players rent, steal their cards or demand money for your birthday. Build up property sets, gather piles of money and keep wheeling and dealing until you're the Monopoly Deal winner! Monopoly and all related characters are trademarks of Hasbro.</p>",
      description_preview:
        " Get a handy way to play the classic property-trading game! Be the first collect 3 full property sets of different colors, and you’ll win the Monopoly Deal Card Game. You’ll pick up cards when it's your turn and play Action cards to charge players rent, steal their cards or demand money for your birthday. Build up property sets, gather piles of money and keep wheeling and dealing until you're the Monopoly Deal winner! Monopoly and all related characters are trademarks of Hasbro. ",
      image_url:
        "https://s3-us-west-1.amazonaws.com/5cc.images/games/uploaded/1559255820851-51PyKtxYE9L.jpg",
      thumb_url:
        "https://s3-us-west-1.amazonaws.com/5cc.images/games/uploaded/1559255820851-51PyKtxYE9L.jpg",
      images: {
        thumb:
          "https://d2k4q26owzy373.cloudfront.net/40x40/games/uploaded/1559255820851-51PyKtxYE9L.jpg",
        small:
          "https://d2k4q26owzy373.cloudfront.net/150x150/games/uploaded/1559255820851-51PyKtxYE9L.jpg",
        medium:
          "https://d2k4q26owzy373.cloudfront.net/350x350/games/uploaded/1559255820851-51PyKtxYE9L.jpg",
        large:
          "https://d2k4q26owzy373.cloudfront.net/700x700/games/uploaded/1559255820851-51PyKtxYE9L.jpg",
        original:
          "https://s3-us-west-1.amazonaws.com/5cc.images/games/uploaded/1559255820851-51PyKtxYE9L.jpg",
      },
      url:
        "https://www.boardgameatlas.com/game/levMwXaCM6/monopoly-deal-card-game",
      price: "4.99",
      msrp: "4.99",
      discount: "0.00",
      primary_publisher: "Hasbro",
      publishers: [
        "Hasbro",
        "ASS Altenburger Spielkarten",
        "Basic Fun, Inc.",
        "Cartamundi",
        "Copag Cards",
        "Funskool Games",
        "Korea Boardgames co., Ltd.",
        "Parker Brothers",
      ],
      mechanics: [
        {
          id: "WPytek5P8l",
          url: "https://www.boardgameatlas.com/mechanic/WPytek5P8l/undefined",
        },
        {
          id: "lA3KUtVFCy",
          url: "https://www.boardgameatlas.com/mechanic/lA3KUtVFCy/undefined",
        },
        {
          id: "T8JEFYwoqy",
          url: "https://www.boardgameatlas.com/mechanic/T8JEFYwoqy/undefined",
        },
      ],
      categories: [
        {
          id: "eX8uuNlQkQ",
          url: "https://www.boardgameatlas.com/category/eX8uuNlQkQ/undefined",
        },
        {
          id: "N0TkEGfEsF",
          url: "https://www.boardgameatlas.com/category/N0TkEGfEsF/undefined",
        },
        {
          id: "7rV11PKqME",
          url: "https://www.boardgameatlas.com/category/7rV11PKqME/undefined",
        },
        {
          id: "X8J7RM6dxX",
          url: "https://www.boardgameatlas.com/category/X8J7RM6dxX/undefined",
        },
      ],
      designers: ["", "Katharine Chapman"],
      developers: [],
      artists: [],
      names: [
        "Monopoly Deal",
        "monopoly",
        "card game",
        "Monopoly Deal Card Game",
      ],
      num_user_ratings: 90,
      average_user_rating: 2.8666666666666667,
      official_url:
        "https://hasbrogaming.hasbro.com/en-us/product/monopoly-deal-card-game:E81831FC-FCB7-4E13-9E95-CD4D1AF3DBDB?utm_source=boardgameatlas.com&utm_medium=search&utm_campaign=bga_ads",
      rules_url: "http://monopolydealrules.com/",
      matches_specs: null,
      spec: [],
      reddit_all_time_count: 46,
      reddit_week_count: 0,
      reddit_day_count: 0,
      historical_low_price: 3.99,
      historical_low_date: "2019-03-15T01:02:16.819Z",
      rank: 461,
      trending_rank: 0,
    },
    {
      id: "OIXt3DmJU0",
      name: "Catan",
      year_published: 1995,
      min_players: 3,
      max_players: 4,
      min_playtime: 45,
      max_playtime: 90,
      min_age: 10,
      description:
        "<p>The women and men of your expedition build the first two settlements. Fortunately, the land is rich in natural resources. You build roads and new settlements that eventually become cities. Will you succeed in gaining supremacy on Catan? Barter trade dominates the scene. Some resources you have in abundance, other resources are scarce. Ore for wool, brick for lumber - you trade according to what is needed for your current building projects. Proceed strategically! If you found your settlements in the right places and skillfully trade your resources, then the odds will be in your favor. But your opponents are smart too.</p>\r\n<p>To begin the game, we build the game board using hexagonal terrain tiles. Catan is born - a beautiful island with mountains, pastures, hills, fields, and forests, surrounded by the sea.</p>\r\n<p>Each of us places two small houses on spaces where three terrain hexes meet. They are our starting settlements.</p>\r\n<p>And so it begins. I roll two dice. An “11”! Each terrain hex is marked with a die roll number. Each player who owns a settlement adjacent to a terrain hex marked with the number rolled receives a resource produced by this hex. Hills produce brick, forests produce lumber, mountains produce ore, fields produce grain, and pastures produce wool.</p>\r\n<p>We use these resources to expand across Catan: we build roads and new settlements, or we upgrade our existing settlements to cities. For example, a road costs 1 brick and 1 lumber. If we do not have the necessary resources, we can acquire them by trading with our opponents.</p>\r\n<p>Each settlement is worth 1 victory point and each city is worth 2 victory points. If you expand cleverly, you may be the first player to reach 10 victory points and thus win the game!</p>",
      description_preview:
        " The women and men of your expedition build the first two settlements. Fortunately, the land is rich in natural resources. You build roads and new settlements that eventually become cities. Will you succeed in gaining supremacy on Catan? Barter trade dominates the scene. Some resources you have in abundance, other resources are scarce. Ore for wool, brick for lumber - you trade according to what is needed for your current building projects. Proceed strategically! If you found your settlements in the right places and skillfully trade your resources, then the odds will be in your favor. But your opponents are smart too. \r\n To begin the game, we build the game board using hexagonal terrain tiles. Catan is born - a beautiful island with mountains, pastures, hills, fields, and forests, surrounded by the sea. \r\n Each of us places two small houses on spaces where three terrain hexes meet. They are our starting settlements. \r\n And so it begins. I roll two dice. An “11”! Each terrain hex is marked with a die roll number. Each player who owns a settlement adjacent to a terrain hex marked with the number rolled receives a resource produced by this hex. Hills produce brick, forests produce lumber, mountains produce ore, fields produce grain, and pastures produce wool. \r\n We use these resources to expand across Catan: we build roads and new settlements, or we upgrade our existing settlements to cities. For example, a road costs 1 brick and 1 lumber. If we do not have the necessary resources, we can acquire them by trading with our opponents. \r\n Each settlement is worth 1 victory point and each city is worth 2 victory points. If you expand cleverly, you may be the first player to reach 10 victory points and thus win the game! ",
      image_url:
        "https://s3-us-west-1.amazonaws.com/5cc.images/games/uploaded/1559258096678-51Eiofu9mqL.jpg",
      thumb_url:
        "https://s3-us-west-1.amazonaws.com/5cc.images/games/uploaded/1559258096678-51Eiofu9mqL.jpg",
      images: {
        thumb:
          "https://d2k4q26owzy373.cloudfront.net/40x40/games/uploaded/1559258096678-51Eiofu9mqL.jpg",
        small:
          "https://d2k4q26owzy373.cloudfront.net/150x150/games/uploaded/1559258096678-51Eiofu9mqL.jpg",
        medium:
          "https://d2k4q26owzy373.cloudfront.net/350x350/games/uploaded/1559258096678-51Eiofu9mqL.jpg",
        large:
          "https://d2k4q26owzy373.cloudfront.net/700x700/games/uploaded/1559258096678-51Eiofu9mqL.jpg",
        original:
          "https://s3-us-west-1.amazonaws.com/5cc.images/games/uploaded/1559258096678-51Eiofu9mqL.jpg",
      },
      url: "https://www.boardgameatlas.com/game/OIXt3DmJU0/catan",
      price: "0.00",
      msrp: "49.00",
      discount: "0.15",
      primary_publisher: "KOSMOS",
      publishers: [
        "KOSMOS",
        "Catan Studio",
        "999 Games",
        "Grow Jogos e Brinquedos",
        "Mayfair Games",
      ],
      mechanics: [
        {
          id: "R0bGq4cAl4",
          url: "https://www.boardgameatlas.com/mechanic/R0bGq4cAl4/undefined",
        },
        {
          id: "ohABM4GjbC",
          url: "https://www.boardgameatlas.com/mechanic/ohABM4GjbC/undefined",
        },
        {
          id: "AVY6EvSQTP",
          url: "https://www.boardgameatlas.com/mechanic/AVY6EvSQTP/undefined",
        },
      ],
      categories: [
        {
          id: "7rV11PKqME",
          url: "https://www.boardgameatlas.com/category/7rV11PKqME/undefined",
        },
      ],
      designers: ["Klaus Teuber"],
      developers: [],
      artists: [
        "Volkan Baga",
        "Tanja Donner",
        "Pete Fenlon",
        "Jason Hawkins",
        "Michaela Kienle",
        "Harald Lieske",
        "Michael Menzel",
        "Marion Pott",
        "Matt Schwabel",
        "Franz Vohwinkel",
        "Stephen Graham Walsh",
      ],
      names: [
        "Die Siedler von Catan ",
        "Catan - Das Spiel",
        "Les Colons de Catane",
        "Catan (Колонизаторы)",
        "Catan: Das Spiel",
        "Catan: Die Bordspel",
        "Catan: El Juego",
        "Catan: Gra planszowa",
        "Catan: Il Gioco",
        "Catan: Landnemarnir",
        "Catan: O Jogo",
        "Catan: Osnovna igra",
        "Catan: Telepesei",
        "Catane",
        "Catanin Uudisasukkaat",
        "I Coloni di Catan",
        "I Coloni di Katan",
        "Coloniștii din Catan",
        "Colonizadores de Catan",
        "Los Colonos de Catán",
        "Les Colons de Katane",
        "Os Descobridores de Catan",
        "Los Descubridores de Catán",
        "Els Colons de Catan",
        "Katan",
        "Katan no Kaitakusya",
        "Katanas ieceïotâji",
        "Katanas Ieceļotāji",
        "Katani Asustajad",
        "Katano salos naujakuriai",
        "Katano Salos Naujakuriai",
        "De Kolonisten van Catan",
        "Naseljenci otoka Catan",
        "Naseljenici ostrva Katan",
        "Naseljenici ostrva Katan / Насељеници острва Катан",
        "Die Ontdekkers van Catan",
        "Osadníci z Katanu",
        "Osadnicy z Catanu",
        "Settlers fra Catan",
        "The Settlers fra Catan",
        "Settlers från Catan",
        "The Settlers från Catan",
        "The Settlers of Catan",
        "Die Siedler von Catan",
        "Οι Άποικοι του Κατάν",
        "Заселниците на Катан",
        "Колонизаторы",
        "המתיישבים של קטאן",
        "นักบุกเบิกแห่ง Catan",
        "カタンの開拓者",
        "カタンの開拓者たち",
        "カタンの開拓者たち スタンダード版",
        "卡坦岛",
        "卡坦島",
        "카탄",
        "카탄의 개척자",
      ],
      num_user_ratings: 565,
      average_user_rating: 3.1286401958971837,
      official_url:
        "https://www.catan.com/?utm_source=boardgameatlas.com&utm_medium=search&utm_campaign=bga_ads",
      rules_url: "https://www.catan.com/game/catan#prof-easy",
      weight_amount: 2,
      weight_units: "lbs ",
      size_height: 11.6,
      size_depth: 3,
      size_units: "inches ",
      matches_specs: null,
      spec: [],
      reddit_all_time_count: 934,
      reddit_week_count: 1,
      reddit_day_count: 5,
      historical_low_price: 0,
      historical_low_date: "2020-09-30T21:42:02.137Z",
      rank: 23,
      trending_rank: 6,
    },
    {
      id: "xxXmJiNj5e",
      name: "Sequence",
      year_published: 1982,
      min_players: 2,
      max_players: 12,
      min_playtime: 5,
      max_playtime: 20,
      min_age: 7,
      description:
        "It's fun, it's challenging, it's exciting, it's Sequence! Play a card from your hand, and place a chip on a corresponding space on the game board. When you have five in a row, it's a Sequence. Learn to block your opponents or remove their chips, and watch out for the Jacks - they're wild! With a little strategy and luck, you'll be a winner. Contents: Folding Game Board, 2 Decks of Sequence Cards, 135 Playing Chips and Instructions. For 2-12 players, ages 7 and up.",
      description_preview:
        "It's fun, it's challenging, it's exciting, it's Sequence! Play a card from your hand, and place a chip on a corresponding space on the game board. When you have five in a row, it's a Sequence. Learn to block your opponents or remove their chips, and watch out for the Jacks - they're wild! With a little strategy and luck, you'll be a winner. Contents: Folding Game Board, 2 Decks of Sequence Cards, 135 Playing Chips and Instructions. For 2-12 players, ages 7 and up.",
      image_url:
        "https://s3-us-west-1.amazonaws.com/5cc.images/games/uploaded/1559257958590-51gFpuIyT8L.jpg",
      thumb_url:
        "https://s3-us-west-1.amazonaws.com/5cc.images/games/uploaded/1559257958590-51gFpuIyT8L.jpg",
      images: {
        thumb:
          "https://d2k4q26owzy373.cloudfront.net/40x40/games/uploaded/1559257958590-51gFpuIyT8L.jpg",
        small:
          "https://d2k4q26owzy373.cloudfront.net/150x150/games/uploaded/1559257958590-51gFpuIyT8L.jpg",
        medium:
          "https://d2k4q26owzy373.cloudfront.net/350x350/games/uploaded/1559257958590-51gFpuIyT8L.jpg",
        large:
          "https://d2k4q26owzy373.cloudfront.net/700x700/games/uploaded/1559257958590-51gFpuIyT8L.jpg",
        original:
          "https://s3-us-west-1.amazonaws.com/5cc.images/games/uploaded/1559257958590-51gFpuIyT8L.jpg",
      },
      url: "https://www.boardgameatlas.com/game/xxXmJiNj5e/sequence-game",
      price: "17.97",
      msrp: "0.00",
      discount: "0.00",
      primary_publisher: "Goliath",
      publishers: ["Goliath"],
      mechanics: [],
      categories: [],
      designers: [],
      developers: [],
      artists: [],
      names: ["Sequence", "Sequence: Deluxe Edition"],
      num_user_ratings: 71,
      average_user_rating: 2.9119718309859155,
      official_url: null,
      rules_url: "https://www.jaxgames.com/sequence-game-rules/",
      weight_amount: 8,
      weight_units: "lbs",
      size_height: 10,
      size_width: 8.5,
      size_depth: 10,
      size_units: "inches",
      matches_specs: { id: "7bPwbFz3BU" },
      spec: [],
      reddit_all_time_count: 32,
      reddit_week_count: 1,
      reddit_day_count: 2,
      historical_low_price: 9.6,
      historical_low_date: "2019-03-07T12:20:03.570Z",
      rank: 545,
      trending_rank: 843,
    },
  ];
  
  export default mockShelf;