import firebase from 'firebase/app';
// import 'firebase/auth';
// import * as firebase from 'firebase/app';
import 'firebase/auth';
import store from "./store";
// import axios from "axios"

// Firebase set-up
const firebaseConfig = {
  apiKey: "AIzaSyCHRAKHuQO4J0h09MjGuoxh4k2tXyoY4xU",
  authDomain: "scoreboard-e0619.firebaseapp.com",
  projectId: "scoreboard-e0619",
  storageBucket: "scoreboard-e0619.appspot.com",
  messagingSenderId: "40389477417",
  appId: "1:40389477417:web:188ad04b5f19f86f660838",
  //measurementId: "G-LSJW4083L5"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// Utils
const auth = firebase.auth()

// Watch for Firebase user change and state in Vuex store
firebase.auth().onAuthStateChanged(async user => {
  // console.log("Auth state change! Updating crap");
  await store.dispatch("setUserData", user);
  if (user) {
    try {
      let idToken = await user.getIdToken(/* forceRefresh */ true)
      await store.dispatch("setIdToken", idToken);
      // console.log("setIdToken!");
      await store.dispatch("setUserMember");
      // console.log("setUserMember!");
      await store.dispatch("groups/fetch");
      // console.log("all groups: " + store.state.groups.groups);
      await store.dispatch("groups/setCurrentGroup", store.state.groups.groups[0]);
      
      // Re-fetch everything (probably a way to specify only the active page, which would be better, just need to record that in state
      await store.dispatch("collection/fetch");
      await store.dispatch("plays/fetch");
      await store.dispatch("members/fetch");
    }
    catch(error) {
      console.log(error);
    }
  }
  // console.log("Done with onAuthStateChanged processes");
});

export { auth }
